import axios from 'axios';

const JIT_LOGIN_URL = 'https://dev.clarus-care.com/api/v4/auth/jit-login';
const REQUEST_OTP_URL = 'https://dev.restapi.clarus-care.com/api/v1/send-message';
const VERIFY_OTP_URL = 'https://dev.restapi.clarus-care.com/api/v1/verify-otp';
const PARTNER_URL = 'https://dev.clarus-care.com/api/v4/partners/';

export const fetchJitAccessTokenAPI = async () => {
  const response = await axios.post(JIT_LOGIN_URL, {
    is_guest: true,
    partner_id: 9,
  });
  return response.data;
};

export const fetchJitAccessTokenAPINew = async ({ partnerId }) => {
  return axios.post(JIT_LOGIN_URL, {
    is_guest: true,
    partner_id: partnerId,
  });
};

export const getPartnerAPI = async ({ accessToken, partnerId }) => {
  return axios.get(PARTNER_URL + partnerId, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const requestOTPAPI = async (payload, enableOTP) => {
  if (enableOTP === 'true') {
    const response = await axios.post(REQUEST_OTP_URL, {
      type: 'wma-otp',
      action: 'new',
      phone_number: `+1${payload}`,
    });
    return response.data;
  } else {
    await new Promise((resolve) => {
      setTimeout(resolve, 1000); // 10000 milliseconds = 10 seconds
    });

    const mockResponseData = {
      data: {
        otp: 'Ea31Vw',
      },
    };

    return mockResponseData;
  }
};

export const verifyOTPAPI = async ({ inputValue, phoneNumber }) => {
  const response = await axios.post(VERIFY_OTP_URL, {
    otp: inputValue,
    phone_number: `+1${phoneNumber}`,
  });
  return response.data;
};
