// ApiContext.js
import React, { createContext, useContext, useMemo } from 'react';

import _get from 'lodash/get';

import { fetchJitAccessTokenAPINew, getPartnerAPI } from './auth';

const ApiContext = createContext();

export const useApi = () => {
  return useContext(ApiContext);
};

function ApiProvider({ children }) {
  const fetchData = async (partnerId) => {
    try {
      const response = fetchJitAccessTokenAPINew({ partnerId });
      const accessToken = _get(response, 'data.token', '');
      const partner = getPartnerAPI({ accessToken, partnerId });
      return {
        accessToken,
        partner: _get(partner, 'data', {}),
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };
  const apiValue = useMemo(() => ({ fetchData }), []);

  return (
      <ApiContext.Provider value={apiValue}>
          {children}
      </ApiContext.Provider>
  );
}

export default ApiProvider;
