import React, { createContext, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

const AppContext = createContext();

function AppProvider({ children }) {
  const [userData, setUserData] = useState(() => {
    const storedUserData = sessionStorage.getItem('userData');
    return storedUserData ? JSON.parse(storedUserData) : {};
  });

  useEffect(() => {
    sessionStorage.setItem('userData', JSON.stringify(userData));
  }, [userData]);

  function updateUserData(newData) {
    setUserData(newData);
  }

  const memoizedValue = useMemo(
    () => ({
      userData, updateUserData,
    }), [userData, updateUserData]);

  return (
      <AppContext.Provider value={memoizedValue}>
          {children}
      </AppContext.Provider>
  );
}
AppProvider.propTypes = {
  children: PropTypes.node,
};

AppProvider.defaultProps = {
  children: null,
};

export { AppProvider, AppContext };
